


























































import { ErrorManager } from '@/models/error';
import { Shipment } from '@/models/shipment';
import { ShipmentRequest } from '@/models/shipmentRequest';
import { cloneDeep } from 'lodash';
import Vue from 'vue';
import { mapActions } from 'vuex';
export default Vue.extend({
  data() {
    return {
      loading: false,
      dialog: false,

      errorMessage: '',
      trackingNumber: '',

      rulesEqual: [] as string[],

      shipmentRequest: new ShipmentRequest(),
      localShipment: new Shipment()
    };
  },
  computed: {
    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },

    trackingNumberHint(): string | undefined {
      if (!this.localShipment.tracking_number) return;
      else
        return (
          'Enter full tracking number for ' +
          this.localShipment.tracking_number.slice(
            0,
            this.localShipment.tracking_number.length - 5
          ) +
          '...'
        );
    }
  },
  methods: {
    ...mapActions({
      createShipmentRequest: 'shipment/createShipmentRequest'
    }),

    open(shipment: Shipment) {
      this.dialog = true;
      this.localShipment = cloneDeep(shipment);
      this.shipmentRequest.package = shipment.id!;
    },

    clearAllErrors() {
      this.errorMessage = '';
    },

    uploadRequestPackage() {
      this.clearAllErrors();

      if (this.trackingNumber != this.localShipment.tracking_number) {
        this.rulesEqual.push("Tracking Number doesn't match");
        return;
      }

      this.shipmentRequest.package = this.localShipment.id!;
      this.shipmentRequest.status = 'P';

      delete this.shipmentRequest.responder;

      this.loading = true;
      this.createShipmentRequest(this.shipmentRequest)
        .then(() => {
          this.$emit('openSnackbar');
          this.close();
        })
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    close() {
      this.dialog = false;
    }
  }
});
